@import 'styles/utils';

.modalContainer {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 94%;
  transform: translate(calc(-50% - var(--scrollbar-width) /2), 0%);
  @include breakpoint('md') {
    top: 15%;
    width: 94%;
    max-width: calc(1410px * 0.94);
  }
  &:focus-visible,
  &:focus {
    outline: none 0;
  }
}

.content {
  overflow: auto;
  background-color: $white;
  border: none;
  margin: auto;
  max-height: 80vh;
  border-radius: 4px;
  padding: 0;
}

.closeButton {
  position: absolute;
  top: -30px;
  cursor: pointer;
  right: 0;
  border: none;
  padding: 0;
  height: 24px;
  width: 24px;
  background-color: inherit;
  opacity: 1;
  .icon {
    height: 24px;
    width: 24px;
    color: $white;
  }
}
