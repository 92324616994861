@import 'styles/utils';

.wrapper {
  // padding: 0 3%;
  position: relative;
  width: 100%;
}

.imageWrapper {
  position: absolute;
  height: 100%;
  width: 94%;
}

.background {
  position: absolute;
  width: 540px;
  background-color: $grey-20;
  border-radius: 4px;
  z-index: 1;
  height: 100%;
  @include breakpoint('xl') {
    width: 57.5%;
  }
}

.image {
  width: 540px;
  display: block;
  border-radius: 4px;
  height: 100%;
  position: relative;
  z-index: 1;
  object-fit: cover;
  @include breakpoint('xl') {
    width: 57.5%;
  }
}

.imageContentWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  max-width: 49%;
  padding-bottom: 40px;
  padding-left: 40px;
}

.title.title {
  display: block;
  color: $starcar-yellow;
  line-height: 54px;
  margin-bottom: 10px;
}

.subtitle.subtitle {
  display: block;
  color: $white;
  line-height: 32px;
}

.carGroupsWrapper {
  width: 49%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
  row-gap: 5px;
  padding: 40px 0;
  margin-left: auto;
  @include breakpoint('xl') {
    column-gap: 4px;
  }
  @include breakpoint('xxl') {
    column-gap: 5px;
  }
}

.groupWrapper {
  z-index: 1;
  width: calc(50% - 1.5px);
  position: relative;
  border-radius: 4px;
  border: none;
  padding: 0;
  @include breakpoint('xl') {
    width: calc(50% - 2px);
  }
  @include breakpoint('xxl') {
    width: calc(50% - 2.5px);
  }
  &:hover {
    outline: 1px solid $mid-Grey;
  }
  &:active {
    outline: 1px solid $mid-Grey;
  }
}

.chips {
  display: flex;
  column-gap: 5px;
  justify-content: flex-end;
  align-items: center;
}

.electro {
  border-radius: 2px;
  background-color: $grey-90;
  padding: 3px 4px 4px;
  width: fit-content;
  color: $white;
  line-height: 18px;
}

.electricity {
  border-radius: 2px;
  background-color: $electric-green;
  padding: 3px 4px 4px;
  width: fit-content;
  color: $white;
  line-height: 18px;
}

.groupImage {
  height: auto;
  width: 100%;
  display: block;
  margin: 25px 0;
}

.exampleCar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  height: 24px;
  width: 24px;
}

.backgroundMobile {
  position: absolute;
  height: 400px;
  width: 100%;
  background-color: $grey-20;
  border-radius: 4px;
}

.imageMobile {
  width: 100%;
  height: 400px;
  display: block;
  border-radius: 4px;
  z-index: 0;
  object-fit: cover;
}

.imageContentWrapperMobile {
  padding: 50px 3%;
}

.titleMobile.titleMobile {
  display: block;
  color: $starcar-yellow;
  line-height: 30px;
  margin-bottom: 0;
  @include breakpoint('sm') {
    line-height: 54px;
    margin-bottom: 15px;
  }
}
.subtitleMobile.subtitleMobile {
  display: block;
  color: $white;
  line-height: 24px;
}

.groupsWrapperMobile {
  padding-left: 3%;
  padding-right: 0;
  display: flex;
  overflow: scroll;
  max-width: 100%;
  position: relative;
  margin-top: -17px;
  z-index: 3;
}

.groupContainerMobile {
  padding-right: 5px;
  z-index: 3;
  flex: 1;
  &:last-of-type {
    padding-right: 0;
  }
  @include breakpoint('sm') {
    padding-right: 2%;
    flex: 0 0 33%;
  }
}

.groupWrapperMobile {
  width: 150px;
  border-radius: 4px;
  height: 100%;
  padding: 10px 10px 12px 10px;
  cursor: pointer;
  border: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  @include breakpoint('sm') {
    width: 100%;
  }
  &:hover {
    outline: 1px solid $mid-Grey;
  }
  &:active {
    outline: 1px solid $mid-Grey;
  }
}

.chipsMobile {
  display: flex;
  row-gap: 5px;
  column-gap: 5px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: auto;
  @include breakpoint('md') {
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.groupNameMobile {
  font-size: 14px;
  line-height: 20px;
}
